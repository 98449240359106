/* customize JSON viewer */
.request-schema-viewer .int-value {
  display: none;
}

.request-schema-viewer .string-value {
  display: none;
}

.request-schema-viewer .data-type-label {
  font-size: 14px;
}
