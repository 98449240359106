html,
#root,
#root > div {
  height: 100vh;
  background-color: rgb(250, 250, 251);
}

/* モーダルよりローディング画面を前面にするために、z-indexを変更 */
._loading_overlay_overlay {
  z-index: 2000 !important;
}

/* Dialog.tsxで表示するダイアログの位置を中央寄せから画面上部にするために、align-itemsを変更 */
.MuiDialog-scrollPaper {
  align-items: flex-start !important;
}
